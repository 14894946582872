<template>
  <div class="interview">
    <div class="heard-tab row-center text-main">
      <div @click="tabClcik(0)" :class="tabIndex == 0 ? 'tab-class' : ''">待面试</div>
      <div @click="tabClcik(1)" :class="tabIndex == 1 ? 'tab-class' : ''">历史记录</div>
    </div>
    <div class="teb-heard space-between">
      <div class="row-center">
        <div class="row-center div-select">
          <div class="font-normal text-main">面试职位</div>
          <div class="select-input">
            <el-select v-model="query.position_id" placeholder="请选择">
              <el-option
                v-for="item in optionsOne"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row-center div-select">
          <div class="font-normal text-main">候选人</div>
          <div class="select-input">
            <el-input placeholder="请输入姓名" v-model="query.name" clearable> </el-input>
          </div>
        </div>
        <div class="row-center div-select">
          <div class="font-normal text-main">面试状态</div>
          <div class="select-input">
            <el-select v-model="query.status" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div
        class="pointer bg-blue text-white font-normal herd-search flex-center"
        @click="searchClick"
      >
        搜索
      </div>
    </div>
    <!-- table表 -->
    <div class="order-table order-table-one">
      <div class="table-center">
        <el-table :data="tableData" max-height="646" style="width: 100%">
          <el-table-column
            prop="interview_time"
            label="面试时间"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="position_name" label="职位" width="180" align="center">
          </el-table-column>
          <el-table-column prop="user_name" label="候选人" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.user.name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="hr_phone" label="手机号码" align="center">
          </el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 0">待接受</span>
              <span v-if="scope.row.status == 1">已接受</span>
              <span v-if="scope.row.status == 2">已拒绝</span>
              <span v-if="scope.row.status == 3">已完成</span>
              <span v-if="scope.row.status == 4">已过期</span>
            </template>
          </el-table-column>
          <el-table-column prop="kj" label="操作" align="center">
            <template slot-scope="scope">
              <span class="pointer text-blue font-normal" @click="updateClick(scope.row)"
                >修改</span
              >
            </template>
          </el-table-column>
          <!-- 没有数据的时候 -->
          <template slot="empty">
            <div class="empty-img">
              <img src="../../../assets/glxt/26.png" alt="" />
              <span>暂无数据</span>
            </div>
          </template>
        </el-table>
        <!-- 分页组件 -->
        <div class="pagination-div pagination-el-div">
          <el-pagination
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="currentChange"
            @size-change="sizeChage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 修改弹窗 -->
    <mask-model
      ref="maskModel"
      width="482px"
      sureContent="发送"
      :interview="false"
      @submitClick="submitClick"
      title="发送面试邀请"
    >
      <div class="update-eidt">
        <div class="row-center eift-item">
          <div class="text-main font-normal">面试职位：</div>
          <div class="div-select">
            <el-select
              v-model="interObj.position_id"
              @change="posiChange"
              placeholder="请选择"
            >
              <el-option
                v-for="item in optionsTwo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row-center eift-item-one">
          <div class="text-main font-normal">面试时间：</div>
          <div class="row select-item">
            <div class="select-item-div select-item-div-one">
              <el-date-picker
                v-model="timeOne"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
            <div class="select-item-div">
                <el-time-select
                v-model="timetwo"
                :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '18:30',
                }"
                placeholder="任意时间点"
                value-format="HH:mm"
              >
              </el-time-select>
            </div>
          </div>
        </div>
        <div class="row-center eift-item">
          <div class="text-main font-normal">面试地址：</div>
          <div class="div-select">
            <el-input placeholder="请输入详细地址" v-model="interObj.address" clearable>
            </el-input>
          </div>
        </div>
        <div class="row-center eift-item">
          <div class="text-main font-normal">联系人姓名：</div>
          <div class="div-select div-select-one">
            <el-input placeholder="请输入联系人姓名" v-model="interObj.hr_name" clearable>
            </el-input>
          </div>
        </div>
        <div class="row-center eift-item">
          <div class="text-main font-normal">联系方式：</div>
          <div class="div-select">
            <el-input
              placeholder="请输入联系方式"
              v-model="interObj.hr_phone"
              clearable
            >
            </el-input>
          </div>
        </div>
        <div class="row-center eift-item">
          <div class="text-main font-normal">备注事项：</div>
          <div class="div-select">
            <el-input
              type="textarea"
              placeholder="请输入备注事项，最多140字"
              v-model="interObj.remark"
              maxlength="140"
              show-word-limit
            >
            </el-input>
          </div>
        </div>
      </div>
    </mask-model>
  </div>
</template>

<script>
import TIM from "tim-js-sdk";
import maskModel from "../../../components/mask.vue";
import api from "../../../api/recruiter.js";
import entApi from "../../../api/enterprises";
import userApi from "../../../api/user";
import { mapState } from "vuex";
export default {
  components: { maskModel },
 computed: {
    // 2. 解构状态作为计算属性
    ...mapState(["userDetail","entDetail"]),
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },

      tabIndex: 0,
      options: [
        {
          value: 0,
          label: "待接受",
        },
        {
          value: 1,
          label: "已接受",
        },
        {
          value: 2,
          label: "已拒绝",
        },
        {
          value: 3,
          label: "已完成",
        },
      ],
      value: "",
      input: "",
      tableData: [],
      total: 0,
      currentPage4: 1,
      value2: new Date(2016, 9, 10, 8, 30),
      optionsOne: [],
      optionsTwo: [],
      query: {
        is_history: 0,
        name: "",
        status: "",
        position_id: "",
        page: 1,
        pageSize: 10,
      },
      // 邀约面试后端所需的数据
      interObj: {
        user_id: "", //用户id
        enterprise_id: "", //企业id
        ent_name: "", //企业名称
        position_id: "", //职位id
        position_name: "", //职位名称
        province_id: "", //省份id,
        province: "", //省名称
        city_id: "", //市id
        city: "", //市名称
        address: "", //详细地址
        interview_time: "", //面试时间
        hr_name: "",
        hr_phone: "",
        remark: "",
      },
      timeOne: "",
      timetwo: "",
    };
  },

  created() {
    this.entPosiList();
    this.getList();
  },

  mounted() {},

  methods: {
    // im发送自定义消息
    sendSay(payload) {
      // 发送文本消息，Web 端与小程序端相同
      // 1. 创建消息实例，接口返回的实例可以上屏
      let message = this.$tim.createCustomMessage({
        to: this.chartId,
        conversationType: TIM.TYPES.CONV_C2C,
        payload,
      });
      // 2. 发送消息
      let promise = this.$tim.sendMessage(message);
      promise
        .then((imResponse) => {})
        .catch(function (imError) {
          // 发送失败
          console.warn("sendMessage error:", imError);
        });
    },
    posiChange(e) {
      let optionActive = this.optionsTwo.filter((item) => {
        return item.value == e;
      });
      this.interObj.position_name = optionActive[0].label;
    },
    yzgz() {
      if (this.interObj.position_id == "") {
        this.$util.msg("请选择面试职位", "warning");
        return false;
      } else if (this.timeOne == "") {
        this.$util.msg("请选择面试日期", "warning");
        return false;
      } else if (this.timetwo == "") {
        this.$util.msg("请选择面试时间", "warning");
        return false;
      } else if (this.interObj.hr_name == "") {
        this.$util.msg("请填写联系人", "warning");
        return false;
      } else if (this.interObj.hr_phone == "") {
        this.$util.msg("请填写联系方式", "warning");
        return false;
      } else if (this.interObj.address == "") {
        this.$util.msg("请填写面试地址", "warning");
        return false;
      } else {
        return true;
      }
    },
    // 提交修改
    submitClick() {
      if (!this.yzgz()) return;
      this.interObj.interview_time = this.timeOne + " " + this.timetwo;
      let query = {
        user_id: this.interObj.user.id, //用户id
        user: this.interObj.user.user_name, //用户名称
        head: this.interObj.user.avatar_url, //用户头像
        ent_name: this.entDetail.ent_name, //企业名称
        ent_id: this.entDetail.id, //企业id
        ent_logo: this.entDetail.logo_url, //企业logo
        contacts: this.userDetail.user_name, //企业hr名字
        jopId: this.interObj.position_id, //工作id
        jop: this.interObj.position_name, //职位名称
        customStatus: 1, //沟通状态
        id: this.userDetail.id + "_2", //hrid
      };
      let interviewObj = {
        position: this.interObj.position_name,
        positionValue: this.interObj.position_id,
        time: this.interObj.interview_time,
        address: this.interObj.address,
        name: this.interObj.hr_name,
        phone: this.interObj.hr_phone,
        remark: this.interObj.remark,
      };
      let message = this.$tim.createCustomMessage({
        to: this.interObj.user.id.toString(),
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
          data: "inte",
          description: JSON.stringify(interviewObj),
          extension: JSON.stringify(query),
        },
      });
      // 2. 发送消息
      let promise = this.$tim.sendMessage(message);
      promise
        .then((imResponse) => {
          // console.log('发送成功',imResponse)
        })
        .catch(function (imError) {
          // 发送失败
          console.warn("sendMessage error:", imError);
        });

      userApi.sendInterView(this.interObj).then((res) => {
        if (res.code == 200) {
          this.getList();
          this.$util.msg("修改成功");
          this.$refs.maskModel.hide();
          this.$util.objClear(this.interObj);
          this.timeOne = "";
          this.timetwo = "";
        }
      });
    },
    currentChange(e) {
      this.query.page = e;
      this.getList();
    },
    sizeChage(e) {
      this.query.pageSize = e;
      this.getList();
    },
    getList() {
      api.getInterviewList(this.query).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    // 搜索
    searchClick() {
      this.query.page = 1;
      this.query.pageSize = 10;
      this.getList();
    },
    // 获取企业发布的职位
    entPosiList() {
      let entDetail = JSON.parse(localStorage.getItem("entDetail"));
      let id = entDetail.id;
      entApi.simpleList({ enterprise_id: id }).then((res) => {
        if (res.code == 200) {
          res.data.unshift({
            id: "",
            position_name: "全部",
          });
          this.optionsOne = res.data.map((item) => {
            return {
              value: item.id,
              label: item.position_name,
            };
          });
          let optionsTwo = res.data.map((item) => {
            return {
              value: item.id,
              label: item.position_name,
            };
          });
          optionsTwo.shift();
          this.optionsTwo = optionsTwo;
        }
      });
    },
    tabClcik(index) {
      this.tabIndex = index;
      this.query.is_history = index;
      this.query.page = 1;
      this.query.pageSize = 10;
      this.getList();
    },
    updateClick(item) {
      this.interObj = item;
      let timeList = item.interview_time.split(" ");
      this.timeOne = timeList[0];
      this.timetwo = timeList[1];
      this.$refs.maskModel.show();
    },
  },
};
</script>
<style lang="less" scoped>
.interview {
  width: 100%;
  padding: 30px 147px;
  .heard-tab {
    margin-bottom: 20px;
    font-size: 20px;
    div {
      margin-right: 28px;
      cursor: pointer;
    }
    .tab-class {
      color: #126bf9;
      font-size: 24px;
    }
  }
  .teb-heard {
    width: 100%;
    height: 62px;
    background: #ffffff;
    margin-bottom: 10px;
    padding: 0 37px 0 36px;
    .div-select {
      margin-right: 40px;
      .select-input {
        margin-left: 10px;
        width: 156px;

        /deep/.el-input__inner {
          height: 33px;
          line-height: 33px;
        }
        /deep/.el-input__icon {
          line-height: 33px;
        }
      }
    }
    .herd-search {
      width: 78px;
      height: 28px;
      border-radius: 2px;
    }
  }
  .order-table {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);
    height: 44.75rem;
    padding: 1.25rem 2.3125rem 2.0625rem 2.25rem;
    .table-center {
      // border: 1px solid #f2f2f2;
      width: 100%;
      height: 100%;
      position: relative;
      /deep/.el-table--fit {
        max-width: 82.6875rem;
        height: 40.375rem !important;
      }
      /deep/.el-table__body-wrapper {
        max-height: calc(40.375rem - 48px) !important;
      }
      /deep/.el-table th.el-table__cell {
        background: #f9f9f9;
      }
      .empty-img {
        height: 40.375rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 205px;
          height: 182px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .order-table-one {
    height: 46rem;
  }
  .pagination-div {
    margin-top: 0.625rem;
  }
  .pagination-el-div {
    margin-top: 1rem;
  }
  .update-eidt {
    width: 100%;
    margin: 30px 0;
    padding-right: 30px;

    .eift-item {
      margin-bottom: 21px;
      .div-select {
        width: 290px;
        margin-left: 20px;
        /deep/.el-input--suffix {
          width: 290px;
        }
        /deep/.el-textarea__inner {
          height: 90px;
        }
      }
      .div-select-one {
        margin-left: 6px;
      }
    }
    .eift-item-one {
      margin-bottom: 21px;
      .select-item {
        margin-left: 20px;
        .select-item-div {
          width: 140px;
          /deep/.el-input {
            width: 140px;
          }
        }
        .select-item-div-one {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
